.statistics {
  padding: 1rem;
  color: white;

  .statList {
    margin: 0;
    padding: 0;
    list-style-type: none;

    .listLine {
      display: flex;
      margin: 0;
      padding: 0;

      .data {
        padding: 0 0.5rem;
      }

      .label {
        margin: 0;
        font-weight: 700;
      }
    }
  }

  @media screen and (min-width: 768px) {
    order: 4;
    max-width: 560px;
    margin: 0 auto;
    padding: 1rem 0;
  }
}

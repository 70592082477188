.barracks {
  padding: 0 1rem 2rem;
  position: relative;

  .barracksTroops {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.5rem 0.5rem;

    .troop {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      .count {
        color: #c9d3de;
        font-weight: bold;
        display: flex;
        align-items: center;

        @media screen and (min-width: 768px) {
          padding-right: 0.5rem;
        }
      }

      .imageContainer {
        border-radius: 4px;
        width: 75px;
        height: 75px;
        overflow: hidden;
        position: relative;
        display: flex;
        flex-flow: column;
        align-items: center;

        &.common {
          border: 2px solid #3098bd;
        }

        &.rare {
          border: 2px solid #3098bd;
        }

        &.epic {
          border: 2px solid #9623d8;
        }

        &.legendary {
          border: 2px solid #c59d2c;
        }

        .image {
          width: 100%;
          height: auto;
        }

        .level {
          position: absolute;
          color: #313131;
          bottom: 4px;
          font-weight: 700;
          z-index: 2;
          background-color: gold;
          border: 2px solid #313131;
          width: 20px;
          height: 20px;
          border-radius: 20px;
          font-size: 0.875rem;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 1.1;
        }
      }

      .removeTroop {
        border-radius: 25px;
        width: 1.5rem;
        height: 1.5rem;
        border: 0;
        outline: 0;
        font-size: 1rem;
        margin-bottom: 1rem;
        align-self: center;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        color: #ff4122;
        cursor: pointer;
        position: absolute;
        top: -0.75rem;
        right: -0.75rem;
      }

      @media screen and (min-width: 768px) {
        justify-content: flex-end;
      }
    }

    @media screen and (min-width: 768px) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  @media screen and (min-width: 768px) {
    order: 4;
    display: flex;
    flex-direction: column;
    max-width: 560px;
    width: 100%;
    align-self: center;
    padding: 0;
  }

  @media screen and (max-width: 960px) and (orientation: landscape) {
    display: none;
  }
}

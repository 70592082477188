.app {
  padding-bottom: 50px;

  .notificaitonMessage {
    position: fixed;
    top: 0;
    color: white;
    width: 100vw;
    left: 0;
    min-height: 1rem;
    background-color: #338d46;
    padding: 1rem;
    text-align: center;
    z-index: 5;
  }

  .formationShare {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    .formationBoard {
      display: grid;
      grid-template: repeat(7, 1fr) / repeat(7, 1fr);
      grid-gap: 0.25rem;
      width: 100vw;
      height: calc(100vw - 2rem);
      padding: 0 1rem;

      @media screen and (min-width: 768px) {
        grid-template: repeat(7, 75px) / repeat(7, 75px);
        width: auto;
        height: auto;
        padding: 0;
        order: 2;
      }
    }

    @media screen and (min-width: 768px) {
      order: 5;
      flex-direction: row;
      align-items: flex-start;
    }

    @media screen and (max-width: 960px) and (orientation: landscape) {
      display: none;
    }
  }

  .backdrop {
    background-color: black;
    opacity: 0.7;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
  }

  .screenRotationNotification {
    display: none;
    text-align: center;
    color: white;
    font-size: 2rem;
    line-height: 4rem;
    flex: 1;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: 768px) {
      order: 2;
    }

    @media screen and (max-width: 960px) and (orientation: landscape) {
      display: flex;
    }
  }

  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  @media screen and (max-width: 960px) and (orientation: landscape) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    padding: 1rem;
  }
}

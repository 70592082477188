.settings {
  padding: 1rem;

  @media screen and (min-width: 768px) {
    order: 4;
    max-width: 560px;
    margin: 0 auto;
    padding: 1rem 0;
  }
}

.heroBar {
  display: flex;
  justify-content: center;
  padding: 1rem;
  position: relative;
  flex-direction: column;

  .hero {
    position: relative;
    width: 80px;
    height: 95px;
    overflow: hidden;
    border-radius: 4px;
    border: 2px solid #657382;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #c9d3de;
    cursor: pointer;
    outline: 0;
    padding: 0;

    &.common {
      border: 2px solid #3098bd;
    }

    &.rare {
      border: 2px solid #3098bd;
    }

    &.epic {
      border: 2px solid #9623d8;
    }

    &.legendary {
      border: 2px solid #c59d2c;
    }

    .image {
      width: 100%;
      height: auto;
    }

    .level {
      position: absolute;
      color: #313131;
      bottom: 4px;
      font-weight: 700;
      z-index: 2;
      background-color: gold;
      border: 2px solid #313131;
      width: 24px;
      height: 24px;
      border-radius: 24px;
      font-size: 0.875rem;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1.1;
    }

    .placeholder {
      display: flex;
      color: #313131;
      font-size: 2rem;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
    }
  }

  .stars {
    width: 80px;
    display: flex;
    justify-content: center;
    padding-top: 0.5rem;
    grid-gap: 0.125rem;
    color: gold;
    position: absolute;
    top: 0px;

    @media screen and (min-width: 768px) {
      position: initial;
      top: auto;
    }
  }

  @media screen and (min-width: 768px) {
    padding: 0 0.5rem 0 0;
    margin-left: -90px;
  }
}

html {
  &[dir="rtl"] {
    .heroBar {
      @media screen and (min-width: 768px) {
        order: 3;
      }
    }
  }

  &[dir="ltr"] {
    .heroBar {
      @media screen and (min-width: 768px) {
        order: 1;
      }
    }
  }
}

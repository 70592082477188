.header {
  text-align: center;
  color: white;
  padding: 1rem;

  h1,
  h2 {
    margin: 0;
  }

  h1 {
    font-size: 1.8rem;

    @media screen and (min-width: 1024px) {
      font-size: 2.5rem;
    }
  }

  h2 {
    font-size: 1.125rem;
    font-weight: 100;
  }

  @media screen and (min-width: 768px) {
    order: 1;
  }
}

.troopSquareContainer {
  .troopSquare {
    width: 100%;
    height: 100%;
    background-color: #c9d3de;
    border: 2px solid #657382;
    outline: 0;
    padding: 0;
    color: #313131;
    font-size: 1rem;
    font-weight: 700;
    line-height: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;

    &.common {
      border: 2px solid #3098bd;
    }

    &.rare {
      border: 2px solid #3098bd;
    }

    &.epic {
      border: 2px solid #9623d8;
    }

    &.legendary {
      border: 2px solid #c59d2c;
    }

    &.common,
    &.rare,
    &.epic,
    &.legendary {
      align-items: flex-start;
    }

    .emptySquareNumber {
      z-index: 1;
      font-size: 1.25rem;
    }

    .troopLevel {
      position: absolute;
      color: #313131;
      bottom: 4px;
      font-weight: 700;
      z-index: 2;
      background-color: gold;
      border: 2px solid #313131;
      width: 23px;
      height: 23px;
      border-radius: 20px;
      font-size: 0.875rem;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1.1;
    }

    .troopImage {
      width: 100%;
      height: auto;
      position: absolute;
      top: 0;
    }

    &.isDragging {
      opacity: 0.5;
    }
  }

  &.isDropping {
    .troopSquare {
      background-color: #95a6b9;

      .troopImage {
        opacity: 0.3;
      }
    }
  }
}

.tabs {
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 50px;
  background-color: #313131;
  z-index: 10;

  .tab {
    flex: 3 3;
    outline: 0;
    background: #313131;
    color: white;
    font-size: 1rem;
    border: 0;
    border-top: 2px solid white;
    border-bottom: 2px solid white;
    padding: 0;
    transition: all 500ms;
    cursor: pointer;
    margin: 0;

    &.icon {
      flex: 1 1;
      font-size: 1.25rem;
      max-width: 50px;
    }

    &.selected,
    &:hover {
      background: #1b1818;
    }
  }

  @media screen and (min-width: 768px) {
    order: 3;
    position: static;
    max-width: 560px;
    margin: 1rem auto 1.5rem;
  }

  @media screen and (max-width: 960px) and (orientation: landscape) {
    display: none;
  }
}

html {
  &[dir="rtl"] {
    .tab:not(:last-child) {
      border-left: 2px solid white;
    }
  }

  &[dir="ltr"] {
    .tab:not(:last-child) {
      border-right: 2px solid white;
    }
  }
}

.formationToolbar {
  padding: 0 1rem;
  margin: 0.5rem 0 2rem;
  display: flex;
  justify-content: space-between;

  .primaryTools {
    display: flex;
    align-items: center;

    .formationPower,
    .formationCount {
      color: white;

      .number {
        margin-left: 0;

        @media screen and (min-width: 375px) {
          margin-left: 0.5rem;
        }
      }
    }
  }

  .btn {
    border: 2px solid white;
    color: white;
    outline: 0;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
    height: 3rem;
    width: 3rem;
    transition: all 500ms;

    &:disabled {
      opacity: 0.5;
      cursor: default;
    }

    &.shareBtn {
      background-color: #338d46;
    }

    &.screenAndDl {
      background-color: #222f8c;
    }

    &.emptyFormation {
      background-color: #c9d3de;
      color: #4a4a4a;
    }
  }

  .iOSImageURI {
    visibility: hidden;
    position: absolute;
  }

  @media screen and (min-width: 768px) {
    order: 4;
    margin: 0.5rem auto 2rem;
    max-width: 560px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0;
  }

  @media screen and (max-width: 960px) and (orientation: landscape) {
    display: none;
  }
}

html {
  &[dir="rtl"] {
    .primaryTools,
    .secondaryTools {
      *:not(:first-child) {
        margin-right: 0.75rem;
      }
    }
  }

  &[dir="ltr"] {
    .primaryTools,
    .secondaryTools {
      *:not(:first-child) {
        margin-left: 0.75rem;
      }
    }
  }
}

.troopSelectionForm {
  position: absolute;
  z-index: 3;
  width: 100%;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  top: 0;

  & > *:not(.close):not(.troopSelectionFormTabs):not(.notification) {
    height: 3rem;
    margin-bottom: 0.25rem;
    outline: 0;
    padding: 0 1rem;
    font-size: 1rem;
    line-height: 1rem;
    border: 2px solid #338d46;
    cursor: pointer;

    &:last-child {
      background-color: #338d46;
      border: 2px solid white;
      color: white;
      font-weight: 700;
    }
  }

  & > :focus {
    outline: 2px solid pink;
  }

  .close {
    border-radius: 25px;
    width: 50px;
    height: 50px;
    border: 0;
    outline: 0;
    font-size: 2rem;
    margin-bottom: 1rem;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: #313131;
    cursor: pointer;
  }

  .troopSelectionFormTabs {
    display: flex;
    width: 100%;
    min-height: 40px;
    margin: 0 0 0.5rem;

    .tab {
      flex: 1 1;
      outline: 0;
      background: #313131;
      color: white;
      font-size: 1rem;
      border: 0;
      border-top: 2px solid white;
      border-bottom: 2px solid white;
      padding: 0;
      transition: all 500ms;
      cursor: pointer;
      padding: 0.25rem 0;

      &.selected,
      &:hover {
        background: #1b1818;
      }
    }
  }

  .notification {
    height: auto;
    background: white;
    padding: 0.75rem;
    margin-bottom: 0.25rem;
    outline: 0;
    font-size: 0.9rem;
    border: 2px solid #338d46;
    cursor: pointer;

    .notificationIcon {
      margin-right: 0.25rem;
    }
  }
}

html {
  &[dir="rtl"] {
    .tab:not(:last-child) {
      border-left: 2px solid white;
    }
  }

  &[dir="ltr"] {
    .tab:not(:last-child) {
      border-right: 2px solid white;
    }
  }
}
